<template>
  <div>
    <booking-main-info :booking="booking" with-services-btn with-orders-btn/>

    <b-row v-if="order">
      <b-col cols="12" md="4">
        <b-card class="full-height">
          <template #default>
            <div class="d-flex justify-content-between mb-2">
              <span>{{ $t('label.id') }}</span>
              <span class="font-weight-bolder">{{ order.id }}</span>
            </div>
            <div class="d-flex justify-content-between mb-2">
              <span>{{ $t('label.date') }}</span>
              <span class="font-weight-bolder">{{ order.date }}</span>
            </div>
            <div class="d-flex justify-content-between mb-2">
              <span>{{ $t('label.status') }}</span>
              <span class="font-weight-bolder">{{ order.status || '-' }}</span>
            </div>
            <div class="d-flex justify-content-between mb-2">
              <span>{{ $t('label.price') }}</span>
              <span class="font-weight-bolder">{{ order.full_amount ? `${order.full_amount} ${order.currency}` : 0 }}</span>
            </div>
          </template>
        </b-card>
      </b-col>
      <b-col cols="12" md="8">
        <b-row>
          <b-col v-for="service in order.services" :key="service.id" md="6" class="mb-2">
            <div class="d-flex align-items-center bg-light-white rounded p-5">
              <div class="d-flex flex-column flex-grow-1 mr-2">
                <div class="font-weight-bold text-dark-75 font-size-lg mb-1">
                  {{ service.name }}
                </div>
                <span class="text-muted font-weight-bold">{{ service.description }}</span>
              </div>
              <div class="d-flex flex-column">
                <div class="d-flex flex-column align-items-end">
                  <span class="font-weight-bolder text-info py-1 font-size-lg">
                    {{ `${service.price} ${service.currency}` }}
                  </span>
                  <span class="text-muted font-size-xs mt-n2">{{ $t('label.price') }}</span>
                </div>
                <div class="d-flex flex-column align-items-end">
                  <span class="font-weight-bolder text-info py-1 font-size-lg">
                    {{ service.count }}
                  </span>
                  <span class="text-muted font-size-xs mt-n2">{{ $t('label.count') }}</span>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import BookingMainInfo from '@/components/bookings/BookingMainInfo';

export default {
  components: {
    BookingMainInfo
  },
  data: () => ({
    bookingId: null,
    orderId: null,
  }),
  beforeMount() {
    this.bookingId = this.$route.params.bookingId;
    this.orderId = this.$route.params.orderId;
    this.$store.dispatch('bookingsStore/GET_DETAILS', this.bookingId);
    this.$store.dispatch('bookingsStore/GET_ORDER', this.orderId);
  },
  computed: {
    ...mapGetters({
      booking: 'bookingsStore/DETAILS',
      order: 'bookingsStore/ORDER',
    })
  }
}
</script>

<style lang="scss" scoped>
.full-height {
  min-height: calc(100vh - 300px);
}
</style>